@import "../bin/vars";

.PageLogin {
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  padding: 40px 15px;
  background-image: url(/img/Login-Bg.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center; }


@import "../template/login/login";
