.Login {
  width: 100%;
  max-width: 330px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  &-Top {
    height: 85px;
    background-color: $main-color;
    display: flex;
    align-items: center;
    justify-content: center; }
  &-Bottom {
    padding: 35px 0 60px; }
  &-Logo {
    width: 81px;
    height: 53px; }
  &-Form {
    max-width: 245px;
    margin: 0 auto; }
  .Form-Btn {
    height: 45px; }
  &-Error {
    display: none; }
  &_error {
    .Login-Error {
      display: block; } } }
